.termsBar {
  width:100%;
  height: 5rem;
  display:grid;
  grid-template-columns: repeat(8,1fr);
  gap:.3rem;
  background-color: #dadada;
  position:fixed;
  top:95px;
  z-index: 98;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  align-items: center;
  padding:0 10px;
}

.large{
  grid-column: auto / span 2;
}

.form {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap:.3rem;
  align-items: center;
}

.form input{
  font-family: var(--type-first);
    padding: 5px 10px;
    border: none;
    border-radius: 10px;
    font-size: 1.1em;
    font-weight: 300;
    margin: 5px 5px;
    height: 40px;
    background-color: var( --backsilver-color);
}

.form button{
  font-size: 1rem;
  font-family: var(--type-second);
  cursor: pointer;
  border: none;
  border-radius: 0.4rem;
  background-color: var(--second-color);
  color: white;
  height: 40px;
  box-sizing: border-box;
  transition: .1s;
}

.form input:focus, .form input:hover {
  outline: none;
  border-color: var(--second-color);
  background-color: white;
  box-shadow: 0 0 0 3px #ffc8b0;
}

.form button:hover,
.form button:focus {
  outline:none;
  box-shadow: 0 0 0 3px #fea, 0 0 0 4px var(--second-color);
}