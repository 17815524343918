.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, .9);
  position:fixed;
  top: 0;
  left: 0;
  z-index: 199;
}

.container {
  max-width: 800px;
  background-color: var(--backgray-color);
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
}

.grupoItem {
  width: 100%;
  padding:10px;
  border-radius: 10px;
  margin: 10px 0;
  background-color: var(--backsilver-color);
}

.grupoItem h3 {
  margin:8px 0;
}

.btAplicar {
  display: flex;
  width: 100%;
  margin:10px 0;
}

.btAplicar button{
  margin-right: 10px;
}
