.logoArea {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
}

.logo{
  margin: 10px;
  width: 95px;
  height: 75px;
  z-index: 1;
  background-image: url('../../Assets/logoTopWhite.svg')
}

@media (max-width: 500px) {
  .logo{
    margin: 10px;
    width: 130px;
    height: 110px;
    z-index: 1;
    background-image: url('../../Assets/logoTopWhite.svg');
    background-position: center center;
    background-repeat: no-repeat;
  }
}