.select {
  font-family: var(--type-first);
  padding: 5px 10px;
  border: none;
  border-radius: 10px;
  font-size: 1.1em;
  font-weight: 300;
  margin: 5px 10px;
  height: 40px;
  background-color: var( --backsilver-color);
}

.combos {
  display: grid;
  grid-template-columns: repeat( 9,1fr);
  gap: .5rem;
  align-items: end;
}

.label {
  font-size: .9rem;
}

.large{
  grid-column: auto / span 2;
}

.btAplicar {
  width: 100%;
  margin:20px 0;
  display: flex;
  justify-content: space-between;
}

.subTitle {
  font-family: var(--type-second);
  font-size: .9rem;
  margin:10px 0 25px 0;
}

.combos select, .combos input[type="number"], .combos input[type="text"]  {
  border: 1px solid #eee;
  display: block;
  width: 100%;
  height: 40px;
  font-size: 1rem;
  font-family: var(--type-first);
  padding: .4rem;
  border-radius: .4rem;
  background-color: white;
  transition: .2s;
} 

.combos select:focus, .combos select:hover, .combos input[type="number"]:focus, .combos input[type="number"], .combos input[type="text"]  :hover {
  outline: none;
  border-color: var(--second-color);
  background-color: white;
  box-shadow: 0 0 0 3px #ffc8b0;
}