.item {
  display:flex;
  flex-wrap: wrap;
  width: 320px;
  height: auto;
  background-color: var(--backsilver-color);
  border-radius: 5px;
  overflow: hidden;
  margin:6px; 
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  position: relative;
  justify-content: center;
}

@keyframes itemIconsHover {
  from {
    opacity: 0;
  } to{
     opacity: 1; 
  }
}

.repeat {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.repeat p{
  font-weight: bold;
}

.repeat input{
  display: flex;
  align-items: center;
  border: 1px solid #eee;
  display: block;
  width: 100%;
  font-size: 1rem;
  padding: .2rem .4rem;
  border-radius: .4rem;
  background-color: #eee;
  transition: .2s;
  width: 60px;
  margin-left: 5px;
}

.repeat input:focus, .repeat input:hover {
  outline: none;
  border-color: var(--second-color);
  background-color: white;
  box-shadow: 0 0 0 3px #ffc8b0;
}

.image {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width:100%;
  height: 160px;
  overflow: hidden;
  background-size: 130%;
  background-position: center center;
  background-color: black;
  z-index: 0;
}

.borderTop {
  border-top: 1px solid gray;
}

.buttons {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  margin-top:10px;
  padding-top: 10px;
}

.move {
  cursor: grab;
  display:flex;
  width: 25px;
  height: 25px;
  margin:5px 10px;
  background-image: url('./../../../Assets/icon_move.svg');
}

.selectExercicio {
  cursor:pointer;
  display:flex;
  width: 25px;
  height: 25px;
  margin:5px 10px;
  background-image: url('./../../../Assets/icon_unselect.svg');
}

.itemSelectedIcon {
  background-image: url('./../../../Assets/icon_selected.svg')!important;
}

.removeExercicio {
  cursor: pointer;
  display:flex;
  width: 35px;
  height: 35px;
  margin:5px;
  background-image: url('./../../../Assets/icon_remove.svg');
}

.editGrupo {
  cursor: pointer;
  display:flex;
  width: 35px;
  height: 35px;
  margin:5px;
  background-image: url('./../../../Assets/icon_edit.svg');
}

.icon:hover {
  filter: invert(54%) sepia(39%) saturate(6769%) hue-rotate(353deg) brightness(96%) contrast(92%);
}

.icon{
  filter: invert(49%) sepia(0%) saturate(1017%) hue-rotate(193deg) brightness(87%) contrast(86%);
}

.overColor {
  display:block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(235,96,24, .6);
}

.overColorSelected {
  background-color: #8579d7d9;
}

.infos {
  margin:15px;
  display:flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
}

.values {
  margin:5px 10px;
  display:flex;
  flex-wrap: wrap;
  width: 100%;
}

.values p{
  width: 100%;
  color:white;
  line-height: 1.3;
  text-shadow: rgba(0, 0, 0, 0.5) 1px 1px 2px;
}


.titulo {
  width: 100%;
  font-size: 1rem;
  font-family: var(--type-second);
  margin-bottom: 5px;
}

.tituloGrupo {
  width: 100%;
  font-size: 1rem;
  font-family: var(--type-second);
  margin-bottom: 5px;
  background-color: var(--second-color);
  padding:10px;
  text-align: center;
  color:white;
}

.itemSelected {
  /*border:3px var(--second-color) solid;*/
/*   outline: 1px solid var(--second-color);
  outline-offset: -1px; */
  background-color: #cec6ff;
}

.grupos {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-height: 60px;
}

.contentGrupos{
  width: 100%;
  display:block;
  padding: 10px;
}

.contentGrupos p{
font-weight: bold;
padding-bottom: 5px;
border-bottom: 1px solid gray;
}
