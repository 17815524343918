.textParq {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.textParq ul{
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem 2rem;
  align-items: baseline;
  margin: 20px 0;
}

.textParq li{
  background-color: var(--backsilver-color);
  border-radius: 5px;
  padding: 10px;
}

.textParq span{
  color: darkorange;
  font-weight: bold;
}

.textParq h2{
  margin: 20px 0;
}