.meuTreino {
  width: 100%;
  display: grid;
  /*grid-template-columns: repeat(4, 1fr);*/
  grid-template-columns: 370px 1fr;
  gap: 0;
  padding-top:95px;
}

.leftBar {
  padding:25px;
  height: calc(100vh - 100px);
  /*background-color: var(--backsilver-color);*/
  background-image: url('./../../../Assets/back_sidebar3.jpg');
  background-repeat: no-repeat;
  background-position: bottom;
}


.title {
  font-family: var(--type-second);
  font-size: 2rem;
  margin:10px 0;
  color: white;
  margin-bottom: 25px;
}

.content {
  width: 100%;
  padding:40px 40px 80px 40px;
}

.textarea {
  margin-top:15px;
}

.basics {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem 2rem;
  align-items: baseline;
  margin-bottom: 10px;
}

.basics2 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.checks {
  display: flex;
  font-weight: bold;
  margin-top: 10px;
}

.checks input{
  margin-right: 6px;
}

.label {
  display: block;
  font-size: 1.1rem;
  line-height: 1;
  padding-bottom: .5rem;
}

.btEnviar {
  margin-top: 25px;
}

/*----Image part---*/

.picContent {
  width:100%;
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top:100px;
}

.inputFile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.inputFile + label {
  font-size: 1rem;
  font-family: var(--type-second);
  cursor: pointer;
  border: none;
  border-radius: .4rem;
  background-color: var(--second-color);
  color:white;
  min-width: 8rem;
  padding: .8rem 1.2rem;
  box-sizing: border-box;
  transition: .1s;
  margin: 25px 0;
}

.inputFile:focus + label,
.inputFile + label:hover {
  outline:none;
  box-shadow: 0 0 0 3px #fea, 0 0 0 4px var(--second-color);
}

.inputFile + label {
	cursor: pointer; /* "hand" cursor */
}

.inputFile:focus + label {
	outline: 1px dotted #000;
	outline: -webkit-focus-ring-color auto 5px;
}

.preview{
  position:relative;
  width:175px;
  height: 175px;
  background-position: center center;
  background-size: cover;
  border-radius: 50%;
  border:1px solid var(--second-color);
}

@media (max-width: 500px) {
  .meuTreino {
    width: 100%;
    display: flex;
    /*grid-template-columns: repeat(4, 1fr);*/
    padding-top:0;
  }

  .leftBar {
   display:none;
  }
  .content {
    width: 100%;
    padding:520px 20px 80px 20px;
  }

  .basics {
    display: flex;
    flex-direction: column;
  }
}