.graphs {
  width: 100%;
  display: flex;
  padding: 20px;
  flex-wrap: wrap;
}

.stats {
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3,1fr);
  margin-bottom: 25px;
  max-width: 1000px;
}

.title1 {
  width: 100%;
  font-size: 1.8rem;
  font-family: var(--type-second);
  margin-bottom: 25px;
}

.subTitle {
  width: 100%;
  font-size: 1.2rem;
  font-family: var(--type-second);
}

.downloadArea {
  display: flex;
  padding:25px 40px;
}

.selData {
  margin: 0 15px;
  padding:5px;
  font-size: 1.2rem;
  font-family: var(--type-second);
  border-radius: 5px;
  border: 2px solid var(--second-color);
}