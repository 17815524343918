.aniversario {
  margin-top: 40px;
  width: 630px;
  height: 400px;
}

@media (max-width: 500px) {
  .aniversario {
    width:90%;
    height: auto;
    margin: 20px 0;
    position: relative;
  }
  
}