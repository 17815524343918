.mainMenu {
  position: absolute;
  left: 0;
  top: 0;
  width:100%;
}

.menuIcon {
    position: absolute;
    top:15px;
    left:15px;
    width: 35px;
    height: 25px;
    cursor: pointer;
    background-image: url('../../Assets/menuIcon.svg');
    z-index: 1;
}

.menuContainer {
  position: absolute;
  display: flex;
  width:inherit;
  height: 100vh;
  padding: 150px 0 0 70px ;
  top: 0;
  left: -370px;
  background-color: rgba(0, 0, 0, .8);
  transition: left 200ms ease-out;
}

.menuContainer li {
  margin:6px 0;
  position: relative;
}

.menuContainer li a{
  font-family: var(--type-second);
  font-size: 1.3rem;
  color: white;
  font-weight: 200;
}

.menuContainer li a:hover{
  color: var(--second-color);
}

.menuContainer li a:hover:before{
  content:'';
  position: absolute;
  top:12px;
  left: -35px;
  width:20px;
  height: 2px;
  background-color: var(--second-color);
}

.menuAtivo {
  left: 0;
  transition: left 200ms ease-in;
}


