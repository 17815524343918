.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  display: grid;
  /*grid-template-columns: repeat(4, 1fr);*/
  grid-template-columns: 370px 2fr 1fr;
  gap: 0;
  max-height: 200px;
}

@media (max-width: 500px) {
  .header {
    position: relative;
    width: 100%;
    z-index: 99;
    display: grid;
    /*grid-template-columns: repeat(4, 1fr);*/
    grid-template-columns:1fr;
    gap: 0;
    max-height: 200px;
  }
}