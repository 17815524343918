.timer {
  width: 100%;
  margin: 10px 0;
  display: flex;
  justify-content: center;
  padding:20px;
  border-radius: 5px;
  background-color: var(--backdark-color);
}

.listTime {
  width: 100%;
  margin: 10px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding:20px;
  border-radius: 5px;
  background-color: silver;
}

.listTime p{
  display: block;
  width: 100%;
  text-align: center;
  font-size: 1.1rem;
}

.timer p{
  font-size: 2rem;
  color:white;
  margin: 0 25px;
  width: 150px;
  text-align: center;
}

.icon:hover {
  filter: invert(54%) sepia(39%) saturate(6769%) hue-rotate(353deg) brightness(96%) contrast(92%);
}

.cron {
    background-image: url('../../../Assets/icon_timer.svg');
    width: 48px;
    height: 48px;
}

.play {
    background-image: url('../../../Assets/icon_play.svg');
    width: 48px;
    height: 48px;
    cursor: pointer;
}

.pause {
    background-image: url('../../../Assets/icon_pause.svg');
    width: 48px;
    height: 48px;
    cursor: pointer;
}