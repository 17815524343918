.alunoTreino {
  width: 100%;
  padding-top:95px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.boxes4 {
  width: 100%;
  max-width: 650px;
  display: grid;
  grid-template-columns: 1fr;
  margin-top:20px;
}

.title {
  font-family: var(--type-second);
  font-size: 2rem;
  margin:10px 0;
  color: white;
  margin-bottom: 25px;
}

@media (max-width: 500px) {
  .alunoTreino {
    padding-top:470px;
  }

  .subTitle {
    width: 100%;
    text-align: center;
    font-family: var(--type-second);
    font-size: 1.8rem;
    margin:30px 0;
    margin-bottom: 25px;
  }
}

.banner {
  display: flex;
  margin: 20px 0;
}


@media (max-width: 500px) {
  .banner {
    width:90%;
    margin: 20px 0;
    position: relative;
  }

  .banner > div {
    height: 200px!important;

  }
  
}

.exercicios {
  width: 100%;
  max-width: 650px;
  margin: 25px auto;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
}

@media (max-width: 500px) {
  .exercicios {
    width: 100%;
    margin: 0;
    padding: 25px;
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr;
  }
}

.exercicios2 {
  width: 100%;
  max-width: 1100px;
  margin: 25px auto;
  display: flex;
  flex-wrap: wrap;
}

.exercicios3 {
  width: 100%;
  max-width: 650px;
  margin: 25px auto;
  display: grid;
}


@media (max-width: 500px) {
  .exercicios3 {
    width: 100%;
    margin: 0;
    padding: 25px;
    display: flex;
    flex-wrap: wrap;
  }
}



@media (max-width: 500px) {
  .exercicios2 {
    width: 100%;
    margin: 0;
    padding: 25px;
    display: flex;
    flex-wrap: wrap;
  }
}

.weekBt {
  display: flex;
  justify-content: space-around;
  width: 100%;
  background-color: var(--backsilver-color);
  border-radius: 5px;
  border: 2px solid #b2b2b2;
  font-size: 1.5rem;
  padding:15px;
  align-items:center;
  cursor: pointer;
}

.weekBtChecked {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin:5px;
  background-color:#c8ffc8;
  border-radius: 5px;
  border: 2px solid #b2b2b2;
  font-size: 1.5rem;
  padding:15px;
  align-items:center;
}

.weekBt:hover {
  background-color: #d0d0d0;
}

.btCheckNull {
    cursor: pointer;
    display:flex;
    width: 48px;
    height: 48px;
    margin:5px 8px;
    background-image: url('./../../../Assets/icon_pending.svg');
}

.btCheck {
    display:flex;
    width: 48px;
    height: 48px;
    margin:5px 8px;
    background-image: url('./../../../Assets/icon_check.svg');
}

.graphics {
  width: 100%;
  max-width: 60rem;
  margin: 0 auto;
  display: grid;
  width: 100%;
  flex-wrap: wrap;
}

.n_ex {
  display: flex;
  justify-content: space-around;
  background-color:#c8ffc8;
  border-radius: 5px;
  font-size: 1.5rem;
  padding:15px;
  align-items:center;
  text-align: center;
}