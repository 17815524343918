.editarTreino {
  width: 100%;
  display: grid;
  /*grid-template-columns: repeat(4, 1fr);*/
  grid-template-columns: 370px 1fr;
  gap: 0;
  padding-top:95px;
}

.leftBar {
  padding:25px;
  height: calc(100vh - 100px);
  background-color: var(--backsilver-color);
}

.title {
  font-family: var(--type-second);
  font-size: 1.6rem;
  margin:10px 0;
}

.btSalvar {
  margin:20px 10px;
}