.button {
  font-size: 1rem;
  font-family: var(--type-second);
  cursor: pointer;
  border: none;
  border-radius: .4rem;
  background-color: var(--second-color);
  color:white;
  min-width: 8rem;
  padding: .8rem 1.2rem;
  box-sizing: border-box;
  transition: .1s;
}

.button:hover,
.button:focus {
  outline:none;
  box-shadow: 0 0 0 3px #fea, 0 0 0 4px var(--second-color);
}

.button:disabled {
  opacity: 0.5;
  cursor: wait;
}