.select {
  font-family: var(--type-first);
  padding: 5px 10px;
  border: none;
  border-radius: 10px;
  font-size: 1.1em;
  font-weight: 300;
  margin: 5px 5px;
  height: 40px;
  background-color: var( --backsilver-color);
}