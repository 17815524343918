.specifyBar {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding:5px 10px 0px 10px;
  border-radius: 10px;
  background-color: #d7d7d7;
}

.combos {
  display: grid;
  grid-template-columns: repeat( 4,1fr);
  gap: .5rem;
}

.label {
  font-size: .9rem;
}

.large{
  grid-column: auto / span 2;
}

.btAplicar {
  width: 100%;
  margin:20px 0;
  display: flex;
  justify-content: space-between;
}

.subTitle {
  font-family: var(--type-second);
  font-size: .9rem;
  margin:10px 0 25px 0;
}

.combos select, .combos input[type="number"] , .combos input[type="text"] {
  border: 1px solid #eee;
  display: block;
  width: 100%;
  height: 40px;
  font-size: 1rem;
  font-family: var(--type-first);
  padding: .4rem;
  border-radius: .4rem;
  background-color: white;
  transition: .2s;
} 

.combos select:focus, .combos select:hover, .combos input[type="number"]:focus, .combos input[type="number"] :hover {
  outline: none;
  border-color: var(--second-color);
  background-color: white;
  box-shadow: 0 0 0 3px #ffc8b0;
}

.btSelects {
  display: flex;
}

.selectAllItens {
  cursor: pointer;
  display:flex;
  width: 30px;
  height: 30px;
  margin:5px;
  background-image: url('./../../../Assets/icon_select_all.svg');
}

.removeAllItens {
  cursor: pointer;
  display:flex;
  width: 30px;
  height: 30px;
  margin:5px;
  background-image: url('./../../../Assets/icon_unselect_all.svg');
}

.cleanSelects{
  cursor: pointer;
  display:flex;
  width: 28px;
  height: 28px;
  margin:5px;
  background-image: url('./../../../Assets/icon_clear.svg');
}

.icon:hover {
  filter: invert(54%) sepia(39%) saturate(6769%) hue-rotate(353deg) brightness(96%) contrast(92%);
}

.icon{
  filter: invert(49%) sepia(0%) saturate(1017%) hue-rotate(193deg) brightness(87%) contrast(86%);
}
