.form {
  position: relative;
  margin-bottom: 2rem;
  margin-top: 2rem;
  
}

.perdeu {
  display: inline-block;
  color:#666;
  padding: 1rem 0 0 0;
  line-height: 1;
  margin-bottom: 10px;
  text-decoration: underline;
  font-weight: 500;
}

/* .perdeu::after {
  content: '';
  height: 2px;
  width: 100%;
  background-color: currentColor;
  display: block;
} */

.links {
  display: flex;
  flex-direction: column;
}

.logo {
    margin: 10px 0 80px 0;
    width: 150px;
    height: 118px;
    background-image: url('/src/Assets/logoDark.svg');
}

.eyePass {
  width: 28px;
  height: 30px;
  background-repeat: no-repeat;
  background-image: url('/src/Assets/eye-regular.svg');
  position: absolute;
  right: 20px;
  bottom: 117px;
  cursor: pointer;
}

.eyePassOff {
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-image: url('/src/Assets/eye-slash-regular.svg');
  position: absolute;
  right: 20px;
  bottom: 117px;
  cursor: pointer;
}

.remember {
  display: flex;
}

.remember input[type='checkbox']{
  margin-right: 10px;
  margin-bottom: 30px;
  background-color: #fff;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid currentColor;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
}

@media (max-width: 500px) {
  section {
    display:flex;
    flex-direction: column;
    align-items: center;
  }

  .form {
    width: 100%;
    padding:15px 0;
  }

  .logo {
    margin: 10px 0 80px 0;
    width: 200px;
    height: 160px;
    background-image: url('/src/Assets/logoDark.svg');
}

.eyePass {
  bottom: 130px;
}

.eyePassOff {
  bottom: 130px;
}

.links {
  align-items: center;
}
}