.editarTreino {
  width: 100%;
  display: flex;
  padding: 20px;
  flex-wrap: wrap;
}

.editarTreino textarea {
  margin:10px 0!important;
  height: 5rem;
  white-space: pre-wrap;
overflow-wrap: break-word;
}

.title {
  width: 100%;
  font-size: 1.8rem;
  font-family: var(--type-second);
  margin-bottom: 25px;
}

.contentSemanas {
  width: 100%;
  display:grid;
  grid-template-columns:  1fr 1fr;
  gap: .5rem;
}

.nameWeek{
  font-weight: bold;
}

.semana {
  background-color: var(--backsilver-color);
  border-radius: 10px;
  padding: 1px 10px;
}

.btSalvar {
  display: flex;
  align-self: flex-end;
    justify-self: flex-end;
}

.btSalvar button{
  font-size: 1rem;
  padding: 0 20px;
  margin: 0 5px;
  font-family: var(--type-second);
  cursor: pointer;
  border: none;
  border-radius: 0.4rem;
  background-color: var(--second-color);
  color: white;
  height: 40px;
  box-sizing: border-box;
  transition: .1s;
}

.btSalvar button:hover,
.btSalvar button:focus {
  outline:none;
  box-shadow: 0 0 0 3px #fea, 0 0 0 4px var(--second-color);
}

.whats {
  display: flex;
  align-items: center;
}

.whats::before {
  margin-right: 8px;
  content:' ';
  display: block;
  width: 25px;
  height: 25px;
  background-size: 25px 25px;
  background-image: url('../../../Assets/icon_whatsapp.svg');  
}

.email {
  display: flex;
  align-items: center;
}

.email::before {
  margin-right: 8px;
  content:' ';
  display: block;
  width: 25px;
  height: 25px;
  background-size: 25px 25px;
  background-image: url('../../../Assets/icon_sendmail.svg');  
}