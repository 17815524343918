.box {
  background-color: var( --backsilver-color);
  height:150px;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding:10px;
  margin: 5px 0;
}

.box span {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.circle {
  padding: 25px;
  border: 2px solid var(--second-color);
  border-radius: 10px;
}

.image {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  overflow: hidden;
}

.nome {
  font-size: 1.2rem;
  font-weight: bold;

}

.label {
  color: var(--second-color);
}

.pontos {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--second-color);
}

.colocacao{
  width: 80px;
  height: 80px;
  background-image: url('./../../../Assets/icon_medal.svg');
  background-repeat: no-repeat;
  background-size: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--second-color);
  font-size: 1.6rem;
  font-weight: bold;
 padding-bottom: 12px;
}

@media (max-width: 500px) {
  .box {
    background-color: var( --backsilver-color);
    height:auto;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding:20px 10px;
    margin: 5px 15px;
    flex-direction: column;
  }

  .box span {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin:10px 0;
  }
}