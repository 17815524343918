.loading {
  width:100%;
  display:flex;
  justify-content: center;
}

.default {
  margin:50px;
  width:100px;
  height: 100px;
  background-image: url('./../../Assets/loading_default.gif');
  background-repeat: no-repeat;
  background-size: contain;
}

.dots {
  width:50px;
  height: 20px;
  background-image: url('./../../Assets/loading_dots.gif');
  background-repeat: no-repeat;
  background-size: contain;
}