.wrapper {
  width: 100%;
  margin: 5px 0;
}

.obs {
  border: 1px solid #eee;
  display: block;
  width: 100%;
  font-size: 1rem;
  padding: .8rem;
  border-radius: .4rem;
  background-color: #eee;
  font-family: var(--type-first);
  transition: .2s;
  white-space: pre-wrap;
}

.obs:focus, .obs:hover {
  outline: none;
  border-color: var(--second-color);
  background-color: white;
  box-shadow: 0 0 0 3px #ffc8b0;
}

.label {
  display: block;
  font-size: 1.1rem;
  line-height: 1;
  padding-bottom: .5rem;
}