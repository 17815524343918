.item {
  display:flex;
  flex-wrap: wrap;
  width: 320px;
  height: auto;
  background-color: var(--backsilver-color);
  border-radius: 5px;
  overflow: hidden;
  margin:6px; 
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.item:hover .overColor, .item:hover .icon {
  display: flex;
  animation: itemIconsHover 300ms;
}

@keyframes itemIconsHover {
  from {
    opacity: 0;
  } to{
     opacity: 1; 
  }
}

.image {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width:100%;
  height: 160px;
  overflow: hidden;
  background-size: 130%;
  background-position: center center;
  background-color: black;
}

/* .image img {
  position: absolute;
  top: 0;
  left: 0;
}
 */
.addRascunho {
  display:none;
  position: absolute;
  top: 6px;
  left: 6px;
  width: 35px;
  height: 35px;
  background-image: url('./../../../Assets/icon_add.svg');
}

/* .btOpenPlay {
  display:none;
  position: absolute;
  width: 60px;
  height: 60px;
  background-image: url('./../../../Assets/icon_openplay.svg');
} */

.editExercicio {
  display:none;
  position: absolute;
  top: 65px;
  left: 10px;
  width: 30px;
  height: 30px;
  background-image: url('./../../../Assets/icon_edit.svg');
}

.removeExercicio {
  display:none;
  position: absolute;
  top: 120px;
  left: 8px;
  width: 25px;
  height: 25px;
  background-image: url('./../../../Assets/icon_delete.svg');
}

.icon:hover {
  cursor: pointer;
  filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(20deg) brightness(102%) contrast(104%);
}

.overColor {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 45px;
  height: 100%;
  background-color: rgba(235,96,24, .8);
}

.infos {
  margin:15px;
  display:flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
}

.titulo {
  width: 100%;
  font-size: 1.1rem;
  font-family: var(--type-second);
  margin-bottom: 5px;
}

.terms {
  font-family: var(--type-first);
  margin-bottom: 8px;
}

