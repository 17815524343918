.form {
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.perdeu {
  display: inline-block;
  color:#666;
  padding: 2rem 0 0 0;
  line-height: 1;
}

.perdeu::after {
  content: '';
  height: 2px;
  width: 100%;
  background-color: currentColor;
  display: block;
}

.logo {
    margin: 10px 0 80px 0;
    width: 150px;
    height: 118px;
    background-image: url('/src/Assets/logoDark.svg');
}

@media (max-width: 500px) {
  section {
    display:flex;
    flex-direction: column;
    align-items: center;
  }

  .form {
    width: 100%;
    padding:15px 0;
  }

  .logo {
    margin: 10px 0 80px 0;
    width: 200px;
    height: 160px;
    background-image: url('/src/Assets/logoDark.svg');
}
}