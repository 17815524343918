.editarCadastro {
  width: 100%;
  padding:40px 20px;
}

.textarea {
  margin-top:15px;
}

.basics {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem 2rem;
  align-items: flex-start;
  margin-bottom: 10px;
}

.large{
  grid-column: auto / span 2;
}

.switch {
  padding-top: 30px;
  width: 175px;
}

.reactSwitch {
  vertical-align: middle;
  margin-left: 10px;
}

.select {
  font-family: var(--type-first);
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  font-size: 1.1em;
  font-weight: 300;
  margin-top: 7px;
  margin-bottom: 1rem;
  height: 55px;
  background-color: var( --backsilver-color);
  width: 100%;
}

.select:focus, .select:hover {
  outline: none;
  border-color: var(--second-color);
  background-color: white;
  box-shadow: 0 0 0 3px #ffc8b0;
}

.label {
  display: block;
  font-size: 1.1rem;
  line-height: 1;
  padding-bottom: .5rem;
}

.btEnviar {
  margin-top: 25px;
}