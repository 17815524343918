.editCategorias {
  width: 100%;
  display: grid;
  /*grid-template-columns: repeat(4, 1fr);*/
  grid-template-columns: 370px 1fr;
  gap: 0;
  padding-top:95px;
}

.leftBar {
  padding:25px;
  height: calc(100vh - 100px);
  /*background-color: var(--backsilver-color);*/
  background-image: url('./../../../Assets/back_sidebar6.jpg');
  background-repeat: no-repeat;
  background-position: bottom;
}

.title {
  font-family: var(--type-second);
  font-size: 2rem;
  margin:10px 0;
  color: white;
  margin-bottom: 25px;
}

.content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 40px 25px;
  align-content: baseline;
}

.content button {
  margin: 25px 0;
}

.selectTerms {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
}

.selectTerms select {
  margin: 0;
}

.boxTerms {
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 0.5rem;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid silver;
  align-items: center;
}