.middleArea {
  background-color: var(--backdark-color);
  display: flex;
  align-items: center;
  padding-left: 30px;
}

.middleArea h2 {
 font-family: var(--type-second);
 color: var(--backgray-color);
}

.middleArea p {
 color: var(--second-color);
}

.userPhotoArea {
  width: 100%;
  display: none;
  justify-content: center;
  flex-wrap: wrap;
  padding: 10px 20px;
}

.instrutor {
  margin-left: 35px;
  padding-left: 35px;
  border-left: 2px solid var(--second-color);
}

.instrutor p{
  color: var(--backgray-color);
}

.displayName{
  display: flex;
}

.image {
  width: 175px;
  height: 175px;
  border-radius: 50%;
  /*background-image: url('../../Assets/placeholder-id.png');*/
}

.dados{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding:20px;
}

.pontos {
  display: flex;
  font-size: 1.2rem;
  max-width: 100px;
  text-align: center;
  color: white;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: var(--second-color);
}

.pontos::before {
  margin-right: 5px;
  content:' ';
  display: block;
  width: 25px;
  height: 25px;
  background-size: 25px 25px;
  background-image: url('../../Assets/icon_star.svg');  
}

@media (max-width: 500px) {
  .middleArea {
    background-color: var(--backdark-color);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 0;
    padding:25px 0;
  }
  
  .middleArea h2 {
   font-family: var(--type-second);
   color: var(--backgray-color);
   text-align: center;
  }
  
  .middleArea p {
   color: var(--second-color);
   text-align: center;
  }

  .userPhotoArea {
    display:flex;
  } 

  .displayName{
    flex-direction: column;
    
  }

  .instrutor {
    border-left: 0;
    margin-left: 0;
    padding-left: 0;
    margin-top:10px;
    padding-top: 10px;
    border-top: 2px solid var(--second-color);
  }

}