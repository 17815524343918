.manageArea {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding:10px;
}

.manageArea > div {
  all: inherit;
}

.noData {
  margin:25px;
  font-family: var(--type-second);
}