.rightArea {
  background-color: var(--backdark-color);
  display: flex;
  justify-content: flex-end;
  padding-right: 25px;
  align-items: center;
}

.modal {
  background-color: rgba(0,0,0,.9);
  display:flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width:100%;
  height: 100%;
  left: 0;
  top:0;
  z-index: 999;
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.close {
  position: absolute;
  top:15px;
  right: 15px;
  background-image: url('../../Assets/icon_close.svg');
  width: 48px;
  height: 48px;
  cursor: pointer;
}

.msg {
  padding: 20px;
  color:white;
  margin:15px;
  border-radius: 5px;
  border: 1px solid var(--second-color);
}

.whatsapp {
  background-image: url('./../../Assets/icon_whatsapp_gray.svg');  
}

.icon_msg {
  background-image: url('./../../Assets/icon_message.svg');
  filter: invert(54%) sepia(39%) saturate(6769%) hue-rotate(353deg) brightness(96%) contrast(92%);
  width: 45px;
  height: 45px;
}

.icon {
  cursor: pointer;
  margin:0 8px;
  width: 30px;
  height: 30px;
}

.home {
  background-image: url('./../../Assets/icon_home.svg');
}

.config {
  background-image: url('./../../Assets/icon_user_config.svg');
}

.message {
  background-image: url('./../../Assets/icon_message.svg');
}

.arrowBack {
  background-image: url('./../../Assets/icon_arrow_back.svg');
}

.counter {
  width: 50px;
  height: 50px;
  background-color: var(--second-color);
  border-radius: 50%;
  color: white;
  font-family: var(--type-second);
  display: flex;
  justify-content: center;
  align-items: center;
}


.logout {
  background-image: url('./../../Assets/icon_logout.svg');
}

@media (max-width: 500px) {
  .rightArea {
    background-color: var(--backdark-color);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px 0;
  }
  
  .icon {
    cursor: pointer;
    margin:0 8px;
    width: 45px;
    height: 45px;
  }
}