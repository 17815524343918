.ranking {
  width: 100%;
  display: grid;
  /*grid-template-columns: repeat(4, 1fr);*/
  grid-template-columns: 370px 1fr;
  gap: 0;
  padding-top:95px;
}

.leftBar {
  padding:25px;
  height: calc(100vh - 100px);
  /*background-color: var(--backsilver-color);*/
  background-image: url('./../../../Assets/back_sidebar4.jpg');
  background-repeat: no-repeat;
  background-position: bottom;
}

.title {
  font-family: var(--type-second);
  font-size: 2rem;
  margin:10px 0;
  color: white;
  margin-bottom: 25px;
}

.content {
display: flex;
flex-direction: column;
flex-wrap: wrap;
padding:35px;
}

.content_ranks {
  width: 100%;
  display: grid;
  /*grid-template-columns: repeat(4, 1fr);*/
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 5px;
}

.rankItem {
  border: 1px solid silver;
  border-radius: 5px;
  padding: 10px;
}

.subTitle {
  width: 100%;
}

.week {
  color: var(--second-color);
  font-weight: bold;
}

.marginTOP {
  margin-top: 50px;
}

.large{
  grid-column: auto / span 2;
}


@media (max-width: 500px) {
  .content {
    padding-top:520px;
  }

  .leftBar{
    display: none;
  }

  .editarUsuario {
    width: 100%;
    display: flex;
    padding-top:0;
  }

  .basics {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .picContent {
    margin-top: 50px;
  }
}