.login {
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 100vh;
  gap: 2rem;
}

.login::before {
  display: block;
  content: '';
  background: url('../../../Assets/login.jpg') center center no-repeat;
  background-size: cover;
}

.form {
  max-width: 30rem;
  padding: 8rem 2rem;
}

@media (max-width: 40rem) {
  .login {
    grid-template-columns: 1fr;
  }
  .login::before {
    display: none;
  }
  .form {
    max-width: 100%;
  }
}