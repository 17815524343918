.wrapper {
  margin-bottom: 1rem;
  width: 100%;
}

.input {
  border: 1px solid #eee;
  display: block;
  width: 100%;
  font-size: 1rem;
  padding: .8rem;
  border-radius: .4rem;
  background-color: #eee;
  transition: .2s;
}

input:focus, input:hover {
  outline: none;
  border-color: var(--second-color);
  background-color: white;
  box-shadow: 0 0 0 3px #ffc8b0;
}

.label {
  display: block;
  font-size: 1.1rem;
  line-height: 1;
  padding-bottom: .5rem;
}

.error {
  color:red;
  font-size: .875rem;
  margin-top: .25rem;
}