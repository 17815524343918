.notify {
  display:none;
  position: absolute;
  top:100px;
  right: -220px;
  width: 220px;
  height: 60px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;

}

.active {
  display:block;
  animation-name: displayBar;
  animation-iteration-count: 1;
  animation-duration: 2s;
}

@keyframes displayBar {
  0% {
    
    right: -220px;
  }

  10% {
    right: 0;
  }

  95% {
    right: 0;
  }

  100% {
    right: -220px;
  }
}

.message {
  color: whitesmoke;
  margin:5px 0 5px 8px;
}

.error {
  background-color: red;
}

.success {
  background-color: limegreen;
}

.progressBar {
  display: block;
  width: 100%;
  height: 10px;
  margin: 5px 0;
}

.progress {
  width: 100%;
  background-color:rgba(255, 255, 255, 0.5);
  height: 100%;
  
  animation-name: progressBar;
  animation-iteration-count: 1;
  animation-duration: 2s;
}



@keyframes progressBar {
  0% {
    width: 1%;
  }

  100% {
    width: 100%;
  }
}