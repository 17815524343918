@import url('https://fonts.googleapis.com/css2?family=Anek+Latin:wght@200;400;700&family=Saira+Condensed:wght@400;700&display=swap');

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  color: var(--first-color);
  --type-first: 'Saira Condensed', sans-serif;
  --type-second: 'Anek Latin', sans-serif;
  --first-color: #706f6f;
  --second-color: #eb6018;
  --backdark-color: #303030;
  --backsilver-color: #ededed;
  --backgray-color: #9d9d9c;
  font-family: var(--type-first);
}

h1, h2,h3,h4,p {
  margin:0;
}

ul, li {
  list-style: none;
  margin: 0;
  padding: 0;
}

img {
  display: block;
  max-width: 100%;
}

button, input {
  display: block;
  font-size: 1rem;
  font-family: var(--type-first);
  color: var(--first-color);
}

.container {
  max-width: 50rem;
  padding: 0 1rem;
  margin: 0 auto;
}

a {
  text-decoration: none;
  color: var(--first-color);
}

/*----classes gerais----*/

.animeLeft {
  opacity: 0;
  transform: translateX(-20px);
  animation: animeLeft .3s forwards;
}

@keyframes animeLeft {
  to {
    opacity: 1;
    transform: initial;
  }
}

.title {
    font-size: 1.5rem;
    font-family: var(--type-second);
}

input:focus {
  box-shadow: none!important;
}

.block {
  position: relative;
  background: white;
  padding: 20px;
  margin-bottom: 10px;
  border: 1px solid lightgray;
  border-radius: 4px;
  cursor: move;
}

.forbidden {
  width:100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top:200px;
  text-align: center;
}

.forbidden::before {
  content:'';
  width: 48px;
  height: 48px;
  background-image: url('./Assets/icon_report.svg');
  vertical-align: middle;
  margin-bottom: 20px;
}

